import React, { useState, useEffect } from 'react';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from '@mui/x-charts/Gauge';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    backgroundColor: '#f5f5f5',
    width: '300px',
    height: '300px',
    borderRadius: '20px',
    padding: '0px 25px',
    
  },
  guage: {
    margin: 'auto',
  },
  referenceArc: {
    fill: '#ef798a !important',
  },
  chartHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '5px',
    lineHeight: '10px',
  },
  chartFooter: {
    textAlign: 'center',
    paddingBottom: '5px',
  },
  tocanCount: {
    position: 'absolute',
    bottom: '40px',
    left: '41.5%',
  },
  tocanHeader: {
    padding: '0px',
    margin: '0px',
    lineHeight: '1px'

  },
  tocanCountText: {
    backgroundColor: '#fa9aa9',
    padding: '5px',
    borderRadius: '5px',
    fontSize: '12px',
  },
});

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={6} fill="#674f87" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="#674f87"
        strokeWidth={3}
      />
    </g>
  );
}

const ProcessingTimeChart = ({ value, totalValue }) => {
  const [animatedValue, setAnimatedValue] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    let start = 0;
    const end = Math.min((value / totalValue) * 100, 100);
    const duration = 1000; // 1 second
    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      setAnimatedValue(Math.min((progress / duration) * end, end));
      if (progress < duration) {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  }, [value, totalValue]);

  return (
    <div className={classes.chartContainer}>
      <div className={classes.chartHeader}>
        <p style={{fontFamily:'Roboto,sans-serif'}}>Processing Time</p>
      </div>
      <div className={classes.tocanCount}>
        <p className={classes.tocanHeader}>Tokens</p>
        <p className={classes.tocanCountText}>{`${value}`} </p>
      </div>
      <GaugeContainer
        className={classes.guage}
        width={200}
        height={200}
        startAngle={-140}
        endAngle={140}
        value={animatedValue}
      >
        <GaugeReferenceArc />
        <GaugeValueArc className={classes.referenceArc}  />
        <GaugePointer />
      </GaugeContainer>
      <div className={classes.chartFooter}>
        <p style={{fontFamily:'Roboto,sans-serif'}}>Processing Time </p>
      </div>
    </div>
  );
};

export default ProcessingTimeChart;
