import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';

const SearchBar = ({ placeholder, onChange, searchBarWidth }) => {
    return (
        <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                background: '#F9F7FF',
                borderRadius: '20px', 
                marginBottom: '30px', 
                width:'90%', 
                border:'solid transparent 1px', 
                borderColor: '#F9F7FF',
                '&:hover': {
                    borderColor: '#d3c4ff', 
                    '& .header-typography': {
                        color: '#000000',
                    },
                }
                }}>


        <SearchIcon sx={{ marginRight: '10px', marginLeft: '10px' }} />
        <Input
            placeholder={placeholder}
            onChange={onChange}
            sx={{width: searchBarWidth, background: '#F9F7FF', fontSize: '1.1rem', borderRadius: '18px'}}
            disableUnderline
        />
        </Box>
    )
}

export default SearchBar
