import { shadows } from "@mui/system";

export const cardStyles = {
    
    card: {
        background: 'linear-gradient(to right, #F9F7FF, #ffffff)', 
        borderRadius: '10px',
        width: '105%',
        height: '120%',
        display: 'flex',
        size: 'small',
        transition: 'border-color 0.3s ease',  
        '&:hover': {
            background: 'linear-gradient(to bottom right, #e6deff, #ffffff)',
            '& .header-typography': {
                color: '#000000',
                textDecoration: 'underline'
            },
        },
        '& span': {
            color: '#555555'
        },
    },    
    
};