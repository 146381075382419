import React, { useState } from 'react';
import convAI from './icons/convAI.svg';
import modelTraining from './icons/modelTraining.svg';
import qc from './icons/qc.svg';
import ochr from './icons/orch.svg';

const ExploreMore = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleHeaderHover = () => {
    setIsHovered(true);
  };

  const handleHeaderLeave = () => {
    setIsHovered(false);
  };

  const exploreMoreStyles = {
    moreContainer: {
      // Add any styles for the more container here
    },
    moreContainerH3: {
      border: isHovered ? '1px solid #ef798a' : '1px solid #000', // Change border color on hover
      width: 'fit-content',
      padding: '2px 5px',
      cursor: 'pointer',
      fontSize: '13px',
      borderRadius: '8px',
    },
    imageContainer: {
      display: 'inline-block',
      marginRight: '50px',
    },
    imageBox: {
      width: '200px',
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(to right, #ef798a 1%, #674f87 40%)',
      borderRadius: '25px',
      cursor: 'pointer',
      transition: 'transform 0.3s ease', // Adding transition for smoother hover effect
      transform: 'scale(1)', // Initial scale
    },
    imageBoxHover: {
      transform: 'scale(1.1)', // Enlarge the image box on hover
    },
    imageBoxImg: {
      maxWidth: '100%',
      maxHeight: '100%',
      cursor: 'pointer',
    },
    description: {
      width: '200px',
      textAlign: 'center',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '20px',
      cursor: 'pointer',
      color: '#000',
      transition: 'color 0.3s ease',
    },
    descriptionHover: {
      color: '#7c7a7a',
    },
  };


  return (
    <div style={exploreMoreStyles.moreContainer}>
      <h3
        style={exploreMoreStyles.moreContainerH3}
        onMouseEnter={handleHeaderHover}
        onMouseLeave={handleHeaderLeave}
      >
        Explore more
      </h3>
      <div style={exploreMoreStyles.imageContainer}>
        <div
          style={{
            ...exploreMoreStyles.imageBox,
            ...(hoveredIndex === 0 && exploreMoreStyles.imageBoxHover),
          }}
          onMouseEnter={() => handleMouseEnter(0)}
          onMouseLeave={handleMouseLeave}
        >
          <img src={convAI} alt="Ai" style={exploreMoreStyles.imageBoxImg} />
        </div>
        <div style={exploreMoreStyles.description}>
          <span
            style={{
              ...exploreMoreStyles.description,
              ...(hoveredIndex === 0 && exploreMoreStyles.descriptionHover),
            }}
          >
            Conversation AI
          </span>
        </div>
      </div>
      <div style={exploreMoreStyles.imageContainer}>
        <div
          style={{
            ...exploreMoreStyles.imageBox,
            ...(hoveredIndex === 1 && exploreMoreStyles.imageBoxHover),
          }}
          onMouseEnter={() => handleMouseEnter(1)}
          onMouseLeave={handleMouseLeave}
        >
          <img src={modelTraining} alt="Model Training" style={exploreMoreStyles.imageBoxImg} />
        </div>
        <div style={exploreMoreStyles.description}>
          <span
            style={{
              ...exploreMoreStyles.description,
              ...(hoveredIndex === 1 && exploreMoreStyles.descriptionHover),
            }}
          >
            Model Training
          </span>
        </div>
      </div>
      <div style={exploreMoreStyles.imageContainer}>
        <div
          style={{
            ...exploreMoreStyles.imageBox,
            ...(hoveredIndex === 2 && exploreMoreStyles.imageBoxHover),
          }}
          onMouseEnter={() => handleMouseEnter(2)}
          onMouseLeave={handleMouseLeave}
        >
          <img src={ochr} alt="Orchestrator" style={exploreMoreStyles.imageBoxImg} />
        </div>
        <div style={exploreMoreStyles.description}>
          <span
            style={{
              ...exploreMoreStyles.description,
              ...(hoveredIndex === 2 && exploreMoreStyles.descriptionHover),
            }}
          >
            Orchestrator
          </span>
        </div>
      </div>
      <div style={exploreMoreStyles.imageContainer}>
        <div
          style={{
            ...exploreMoreStyles.imageBox,
            ...(hoveredIndex === 3 && exploreMoreStyles.imageBoxHover),
          }}
          onMouseEnter={() => handleMouseEnter(3)}
          onMouseLeave={handleMouseLeave}
        >
          <img src={qc} alt="QC console" style={exploreMoreStyles.imageBoxImg} />
        </div>
        <div style={exploreMoreStyles.description}>
          <span
            style={{
              ...exploreMoreStyles.description,
              ...(hoveredIndex === 3 && exploreMoreStyles.descriptionHover),
            }}
          >
            QC console
          </span>
        </div>
      </div>
    </div>
  );
};

export default ExploreMore;
