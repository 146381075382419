
// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Tab from '@mui/material/Tab';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
// import GridWrapper from '../../components/common/GridWrapper/GridWrapper';

// export default function Documentation() {
//   const [value, setValue] = React.useState('1');

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <GridWrapper>
//         <Box sx={{ width: '100%', typography: 'body1' }}>
//         <TabContext value={value}>
//             <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//             <TabList onChange={handleChange} aria-label="lab API tabs example">
//                 <Tab label="Form Data" value="1" />
//                 <Tab label="Table Data" value="2" />
//             </TabList>
//             </Box>
//             <TabPanel value="1">Form Data</TabPanel>
//             <TabPanel value="2">Table Data</TabPanel>
//         </TabContext>
//         </Box>
//     </GridWrapper>
//   );
// }


import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import Docgrid from '../../components/Docgrid/Docgrid';
import SearchBar from '../../components/common/SearchBar/SearchBar';
import ReactImageMagnify from 'react-image-magnify';

const Documentation = () => {

    return (
        <Docgrid></Docgrid>
    )
}

export default Documentation


