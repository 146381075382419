import { colors } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

class BarChart extends React.Component {
  

  constructor(props) {
    super(props);

    this.state = {
      series: [{
        data: [400, 540, 690, 1100, 1380]
      }],
      options: {
        fill: {
            colors: '#ef798a',
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#674f87'], 
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            },
        },
        chart: {
          type: 'bar',
          height: 350,
          
          

        },
        plotOptions: {
          bar: {
            barHeight: '30%',
            borderRadius: 6,
            // borderRadiusApplication: 'end',
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          labels: {
            show: false // hide x-axis labels
          },
          categories: ['OpenAI', 'Llama', 'Google AI', 'Bert', 'H2O GPT'],
        },
        
        
      },
    };
  }

  render() {
    return (
      <div>
        <style>{`
          .apexcharts-menu-icon, .apexcharts-selection-icon {
            display: none !important;
          }
        `}</style>
        <div id="chart" style={{background: '#f5f5f5', borderRadius:'20px', width:'300px', height:'300px',}}>
          <div style={{textAlign:'center', paddingTop:'11px',lineHeight: '0px', fontWeight:'bold'}}>
           <p style={{fontFamily:'Roboto,sans-serif', }}>Integration with Various LLMs </p>
          </div> 
          <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={230} width={280} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default BarChart;