

import React, { useEffect } from 'react';
import './odometer.css'; // Import CSS file
import './ProcessingCount.css'; // Import additional CSS file
import Odometer from './odometer'; // Import Odometer.js library

const CountChart = ({value}) => {
  useEffect(() => {
    const subsOdometer = new Odometer({
      el: document.querySelector('.subs-odometer')
    });
    subsOdometer.update(value);
  }, []);

  return (
    <div className='cardContainer'>
      <div className='chartHeader' style={{fontFamily:'Roboto,sans-serif'}}>
         <h4>Processed</h4>
      </div>
      <div className='outerCircle'>
        <div className='InnerCircle'>
            <div className="subs-odometer">0</div>
        </div>
      </div>
      
    </div>
  );
};

export default CountChart;
