import React, { useState, useEffect, useRef } from 'react';
import './chatBot.css';
import Modal from 'react-modal';
import RecordIcon from '@material-ui/icons/FiberManualRecord';
import MicIcon from '@material-ui/icons/Mic';
import SendIcon from '@material-ui/icons/Send';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import commentIcon from './uploads/message.svg'
import voiceIcon from './uploads/voiceIcon.svg'
import { BACKEND_URL, getStoredEndpoints } from '../../constants/constants';
import { initializeEndpoints, getEndpoint } from '../../constants/endpoints';

Modal.setAppElement('#root');

const Cbot = () => {
  
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundImage: `url(${commentIcon})`, // Use the imported image here
          filter: 'brightness(0) invert(1)', // Set image color to white
          backgroundSize: 'cover', // Fit the image within the thumb
          backgroundPosition: 'center',
          width: '60%', // Set width of the image
          height: '60%',
          margin: '6px',
          id: 'msgIcon',
      },
  },
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
              backgroundImage: `url(${voiceIcon})`, // Use the imported image here
              filter: 'brightness(0) invert(1)', // Set image color to white
              backgroundSize: 'cover', // Fit the image within the thumb
              backgroundPosition: 'center center',
              width: '60%', // Set width of the image
              height: '60%',
              margin: '6px',
              id: 'voiceIcon',
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

  const [inputValue, setInputValue] = useState('');
  const [policy,setPolicy] = useState('');
  const [policy_state,setPolicyState] = useState('');
  const [reset_state,setReset_state] = useState('');
  const [messages, setMessages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null); // State to store recorded audio blob
  const [q_response, setQ_response] = useState(''); // State to store response from server
  // const [uploadedFile, setUploadedFile] = useState(null); // State to store uploaded file
  const messagesEndRef = useRef(null);
  const [checked, setChecked] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const backendUrl = process.env.REACT_APP_BACKEND
  const ToggleMsgOrVoice = () => {
    setChecked(!checked);
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsTyping(e.target.value.trim() !== '');
  };

  useEffect(() => {
    // This effect will run once when the component mounts
    handleAutoMessage();
  }, []);

  const handleAutoMessage = async () => {
    const userMessage = { type: 'user', text: inputValue, sender: 'You' };
    console.log("Endpoint", BACKEND_URL + getEndpoint('CONVERSATION'))
    const formData = new FormData();
    formData.append('question', "CkeckPass");
    formData.append('policy_no', policy);
    formData.append('policy_state', policy_state);
    formData.append('reset_state', "");
    console.log('formData',formData)
    // http://products.quadance.com:8080
    const response = await fetch(BACKEND_URL + getEndpoint('CONVERSATION'), {
        method: 'POST',
        body: formData,
    });
    if (!response.ok) {
      throw new Error('Failed to upload file');
    }
    else {
      const responseData = await response.json();
      const q_response = responseData['answer'];
      console.log('Response:', q_response);
      try{
        const pol = responseData['policy_no'];
        setPolicy(pol)
      }
      catch(dataError){
        const pol = "error"
        setPolicy(pol)
      }
      try{
        const pol_state = responseData['policy_state'];
        setPolicyState(pol_state)
      }
      catch(dataError){
        const pol_state = "error"
        setPolicyState(pol_state)
      } 
      console.log('responseData',responseData)

      setReset_state("started")
      setMessages([
        ...messages,
        userMessage,
        { type: 'bot', text: q_response, sender: 'HR Bot' },
      ]);
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() !== '') {
      const userMessage = { type: 'user', text: inputValue, sender: 'You' };
      if (isRecording) {
        userMessage.audio = recordedAudio;
        userMessage.className = 'audio'; // Add the 'audio' class to identify audio messages
        
      }
      else {
        console.log('User Message:', inputValue);
        console.log("Endpoint", BACKEND_URL + getEndpoint('CONVERSATION'))
        const formData = new FormData();
        formData.append('question', inputValue);
        formData.append('policy_no', policy);
        formData.append('policy_state', policy_state);
        formData.append('reset_state', reset_state);
        console.log('formData',formData)
        // http://products.quadance.com:8080
        const response = await fetch(BACKEND_URL + getEndpoint('CONVERSATION'), {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
          throw new Error('Failed to upload file');
        }
        else {
          const responseData = await response.json();
          const q_response = responseData['answer'];
          console.log('Response:', q_response);
          try{
            const pol = responseData['policy_no'];
            setPolicy(pol)
          }
          catch(dataError){
            const pol = "error"
            setPolicy(pol)
          }
          try{
            const pol_state = responseData['policy_state'];
            setPolicyState(pol_state)
          }
          catch(dataError){
            const pol_state = "error"
            setPolicyState(pol_state)
          } 
          console.log('responseData',responseData)

          setReset_state("started")
          setMessages([
            ...messages,
            userMessage,
            { type: 'bot', text: q_response, sender: 'HR Bot' },
          ]);
        }
        console.log('User response:', response);
      }
      // setMessages([
      //   ...messages,
      //   userMessage,
      //   { type: 'bot', text: 'Lorem ipsum dolor sit amet, consectetuer message?', sender: 'HR Bot' },
      // ]);
      setInputValue('');
      setIsTyping(false);
    }
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Upon successful upload, display a bot message
      const botMessage = {
        type: 'bot',
        text: `File "${file.name}" uploaded successfully.`,
        sender: 'HR Bot'
      };
      setMessages([...messages, botMessage]);
      const formData = new FormData();
      formData.append('file', file);
  
      fetch(backendUrl + '/upload', {
        method: 'POST',
        body: formData
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        // Optionally, display an error message to the user
      });
    }
  };
  

  const handleRecordToggle = () => {
    if (!isRecording) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorderRef.current = mediaRecorder;
          mediaRecorder.ondataavailable = handleDataAvailable;
          mediaRecorder.start();
          setIsRecording(true);
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error);
        });
    } else {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleDataAvailable = async (event) => {
    chunksRef.current.push(event.data);
    if (mediaRecorderRef.current.state === 'inactive') {
      const blob = new Blob(chunksRef.current, { type: 'audio/wav' });
      setRecordedAudio(blob); // Save recorded audio blob to state
      const formData = new FormData();
      formData.append('audio', blob);
      formData.append('policy_no', policy);
      formData.append('audio', blob);
      formData.append('policy_no', policy);
      formData.append('reset_state', reset_state);
      const fullEndpoint = getEndpoint('CONVERSATION')
      const splitEndpoint = fullEndpoint.split('/');
      const extractedEndpoint = splitEndpoint[1]; // This will be "it_conversation"
      // http://127.0.0.1:8081
      console.log(backendUrl + '/'+extractedEndpoint+'/upload-audio')
      const response = await fetch(backendUrl + '/'+extractedEndpoint+'/upload-audio', {
        method: 'POST',
        body: formData,
        responseType: 'blob'
      });
     
      try {
        
        const blob_res = await response.blob();
        setReset_state("started")
        console.log('Audio Response:', blob_res);
        setQ_response(blob_res);
        // const botMessage = { 
        //   type: 'bot', 
        //   audio: blob_res, // Use 'audio' property for audio Blob
        //   sender: 'HR Bot' 
        // };
        // setMessages([...messages, botMessage]); 
        
      }
      catch (error) {
        console.log('Audio Error:', error);
      }
      chunksRef.current = [];
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (recordedAudio) {
      const userAudioMessage = {
        type: 'user',
        audio: recordedAudio,
        sender: 'You'
      };
  
      setMessages([...messages, userAudioMessage]); // Add recorded audio message and bot message to messages state
      setRecordedAudio(null); // Reset recorded audio state
    }
  },  [recordedAudio, messages]);

  useEffect(() => {
    if (q_response!=='') {
      const botMessage = { 
        type: 'bot', 
        audio: q_response, 
        sender: 'HR Bot' 
      };
  
      setMessages([...messages,botMessage]); // Add recorded audio message and bot message to messages state
      setRecordedAudio(null); // Reset recorded audio state
    }
  },  [q_response]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="app">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="chat-modal"
        overlayClassName="chat-overlay"
      >
        <div className="chat-container">
          <div className="header">
            <div className="avatar">
              <img src="./botIcons/header-logo.svg" alt="Robot Avatar" />
            </div>
            <div className="bot-name">Secure Assist</div>


            <FormGroup>
              <FormControlLabel
                control={<MaterialUISwitch checked={checked} onChange={ToggleMsgOrVoice} sx={{ m: 1 }} />}
                label=""
              />
            </FormGroup>

            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
          </div>
          <div className="messages">
            {messages.map((message, index) => (
              <div key={index} className={`message-container ${message.type}`}>
                <div className={`icon ${message.type}`} style={{ backgroundColor:'white',marginLeft:'10px', marginRight: '5px'}}>
                  {message.type === 'user' && (
                    <div className='user-icon'><AccountCircleIcon style={{ fontSize: 30, color: 'grey'}}/></div>
                  )}
                  {message.type === 'bot' && (
                    <img src="./botIcons/header-logo.svg" alt="Bot Icon" />
                  )}
                </div>
                <div className="message">
                  
                  {message.audio ? (
                    <div className="audio-message">
                      <audio controls>
                        <source src={URL.createObjectURL(message.audio)} type="audio/wav" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>                  
                  ) : (
                    <div>
                      <div className="text" data-sender={message.sender}>
                        {message.text}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Enter your message"
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            {isTyping ? (
              <button className='btnsend' onClick={handleSendMessage}>
                <span><SendIcon /></span>
              </button>
            ) : (
              <>
                <button  className='btnrcrd' onClick={handleRecordToggle}>
                  <span style={{ color: isRecording ? 'red' : 'inherit',padding:'0px' }}>
                    {isRecording ?  <RecordIcon /> : <MicIcon />}
                  </span>
                </button>
                <label htmlFor="file-upload" className="btnUpload">
                  <CloudUploadIcon/>
                  <input
                    id="file-upload"
                    type="file"
                    accept=".pdf,.doc,.docx,.txt"
                    onChange={handleUploadFile}
                    style={{ display: 'none' }}
                  />
                </label>
              </>
            )}
          </div>
        </div>
      </Modal>
      <div className="chat-icon" onClick={openModal}>
        <img src="./botIcons/bot-logo.svg" alt="Chat Icon" />
      </div>
    </div>
  );
};

export default Cbot;
