
// export const cardStyles = {
//     icons: {
//         marginLeft: '10px',
//         marginTop: '10px',
//         size: 'small',
//         color: '#5E35B1'
//     },
//     card: {
//         backgroundColor: '#FAFAFA',
//         borderRadius: '10px',
//         display: 'flex',
//         size: 'small',
//         '&:hover': {
//             '& .header-typography': {
//                 color: '#5E35B1',
//             },
//         },
//         '& span': {
//             color: '#555555'
//         },
//     }
// };


export const cardStyles = {
    icons: {
        marginLeft: '10px',
        marginTop: '10px',
        size: 'small',
        color: '#5E35B1',
        
    },
    card: {
        backgroundColor: '#FAFAFA',
        borderRadius: '10px',
        display: 'flex',
        size: 'small',
        transition: 'border-color 0.3s ease', 
        border: '1px solid transparent', 
        '&:hover': {
            // background: 'linear-gradient(to right, #ef798a, #674f87)',
            borderColor: '#ef798a', 
            '& .header-typography': {
                color:'linear-gradient(to right, #ef798a, #674f87)',
            },
        },
        '& span': {
            color: '#555555'
        },
    },    
    cardmedia: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#ffffff',
        '& img': { 
          color: '#674f87',
          fontSize: '3rem',
          width: '50px',
          height: '50px'
        }
      }
};