import React from 'react';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';

import Dropbox from './DropBox'
const UploadDoc = () => {
        return (
            <GridWrapper>
               <Dropbox/>
            </GridWrapper>
        );
   
};

export default UploadDoc;
