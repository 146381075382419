

import React, { useState, useEffect, useRef } from 'react';
import {
  TransformWrapper,
  TransformComponent,
  useControls,
  useTransform,
} from "react-zoom-pan-pinch";

import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import CommonButton from '../../components/common/CommonButton/CommonButton';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import { useNavbar } from '../../components/Navbar/NavbarContext';
import { ReactPictureAnnotation, IShapeStyle } from 'react-picture-annotation';
import image1 from '../images/007896060_00008.jpg';
import Form from './Form';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
function Help(imageUrlIpBase) {

  const [imageUrlIp, setImageUrlIp] = useState(imageUrlIpBase);
  const [count, setCount] = useState(0);
  const { setCollapsed } = useNavbar();
  setCollapsed(true);
  const [value, setValue] = useState('1');
  const [pdf_list, setPdfList] = useState([1,8,19]);
  const [word_list, setWordList] = useState([190]);
  const [imageSrc] = useState('../images/007896060_00008.jpg');
  const [pageSize, setPageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const ocrData = imageUrlIp.all_ocr_data;
  const [annotations, setAnnotations] = useState([]);
  const [selectedAnnotationId, setSelectedAnnotationId] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onResize = () => {
    setPageSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const handleSelect = (selectedId) => {
    setCount(count + 1);
    setSelectedAnnotationId(selectedId);
  };
  const handleExit = () => {
    console.log('Rectangle drawn-2 exit');
  };
 
  const get_ocr_text = () => {
    let text = '';
    const { x, y, width, height } = annotations[0].mark;
    const bbox =[x, y, x + width, y + height];
    ocrData.forEach((item) => {
      const lineBox = item.bbox;
      if (
        lineBox[0] >= bbox[0] &&
        lineBox[1] >= bbox[1] &&
        lineBox[4] <= bbox[2] &&
        lineBox[5] <= bbox[3]
      ) {
        text = `${text} ${item.text}`;
      }
    });
    if (
      imageUrlIp &&
      imageUrlIp.all_table_data &&
      imageUrlIp.all_table_data[currentRowIndex] &&
      imageUrlIp.all_table_data[currentRowIndex][currentColumnIndex]
    ) {
      imageUrlIp.all_table_data[currentRowIndex][currentColumnIndex].Text = text;
      setImageUrlIp({ ...imageUrlIp });
    }
  };
 
  const handleSave = (data) => {
    if (!data.length) return;
    try {
      
      setAnnotations([data[data.length - 1]]);
    } catch (error) {
      console.log('Error saving annotation:', error);
    }
  };

  const scrollContainerRef = useRef(null);
  const handleDelete = (annotationId) => {
    setAnnotations(annotations.filter((annotation) => annotation.id !== annotationId));
    setSelectedAnnotationId(null); // Clear selection after deletion
  };

  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [currentColumnIndex, setCurrentColumnIndex] = useState(null);
  const DrawRectangle = (Coordinates,rowIndex,columnIndex) => {
    setCurrentRowIndex(rowIndex);
    setCurrentColumnIndex(columnIndex);
    const newAnnotation = {
      id: Coordinates,
      comment: "",
      mark: {
        type: "RECT",
        x: Coordinates[0],
        y: Coordinates[1],
        width: Coordinates[2],
        height: Coordinates[3]
      }
    };
    setAnnotations([newAnnotation]); // Add new annotation to the existing list
  };
  const handleWheel = (e) => {
    e.preventDefault(); // Prevent the default behavior of scrolling
  };
  const defaultShapeStyle: IShapeStyle = {
    /** text area **/
    padding: 5, // text padding
    fontSize: 12, // text font size
    fontColor: "#212529", // text font color
    fontBackground: "#432F60", // text background color
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",

    /** stroke style **/
    lineWidth: 2, // stroke width
    shapeBackground: "hsla(210, 16%, 93%, 0.2)", // background color in the middle of the marker
    shapeStrokeStyle: "#BF40BF", // shape stroke color
    shadowBlur: 10, // stroke shadow blur
    shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)", // shape shadow color

    /** transformer style **/
    transformerBackground: "#5c7cfa",
    transformerSize: 10
  };
  
  const tabBackground = (index) => {
    return value === index ? '#ffffff' : 'inherit';
  };

  const longText = imageUrlIp.all_context; 


  const [editingCellT1, setEditingCellT1] = useState(null);
  const [editValueT1, setEditValueT1] = useState('');
  const handleEditT1 = (rowIndex, columnIndex,column) => {
    DrawRectangle(column.Coordinates,rowIndex,columnIndex)
    setEditingCellT1({ rowIndex, columnIndex });
    const text = imageUrlIp.all_table_data[rowIndex][columnIndex].Text;
    setEditValueT1(text);
  };

  const handleInputChangeT1 = (event) => {
    setEditValueT1(event.target.value);
  };

  const handleInputBlurT1 = () => {
    if (editingCellT1 !== null) {
      const { rowIndex, columnIndex } = editingCellT1;
      const updatedTable = [...imageUrlIp.all_table_data];
      updatedTable[rowIndex][columnIndex].Text = editValueT1;
      // Here you can update your state or API with the updated table
      setEditingCellT1(null);
    }
  };


  const [editingCell, setEditingCell] = useState(null);
  const [editValue, setEditValue] = useState('');
  const handleEdit = (rowIndex, columnIndex, column) => {
    DrawRectangle(column.Coordinates,rowIndex,columnIndex)
    setEditingCell({ rowIndex, columnIndex });
    const text = imageUrlIp.sec_table[rowIndex][columnIndex].Text;
    setEditValue(text);
  };

  const handleInputChange = (event) => {
    setEditValue(event.target.value);
  };

  const handleInputBlur = () => {
    if (editingCell !== null) {
      const { rowIndex, columnIndex } = editingCell;
      const updatedTable = [...imageUrlIp.sec_table];
      updatedTable[rowIndex][columnIndex].Text = editValue;
      // Here you can update your state or API with the updated table
      setEditingCell(null);
    }
  };

  const handleDownloadCSV = () => {
    console.log('CSV');
    const tableData = imageUrlIp.sec_table;
    const csvRows = [];

    const headerRow = imageUrlIp.all_table_header.map((header) => header.replace(/,/g, '')); // Remove commas from header
    csvRows.push(headerRow.join(','));

    tableData.forEach((row) => {
        const csvRow = row.map((cell) => {
            let text = cell.Text.replace(/,/g, ''); 
            return `"${text}"`; 
        });
        csvRows.push(csvRow.join(','));
    });

    // Create a CSV string for tableData
    const csvString = csvRows.join('\n');

    // Create a Blob object for tableData
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download for tableData
    const a = document.createElement('a');
    a.href = url;
    a.download = 'table_data.csv';
    document.body.appendChild(a);
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    // Second dataset (tableData2)
    const tableData2 = imageUrlIp.all_table_data;
    const csvRows2 = [];

    // Adding header row for tableData2
    const headerRow2 = imageUrlIp.all_table_header.map((header) => header.replace(/,/g, '')); // Remove commas from header
    csvRows2.push(headerRow2.join(','));

    // Adding data rows for tableData2
    tableData2.forEach((row) => {
        const csvRow = row.map((cell) => {
            let text = cell.Text.replace(/,/g, ''); // Remove commas from cell text
            return `"${text}"`; // Enclose cell text in quotes to handle commas
        });
        csvRows2.push(csvRow.join(','));
    });

    // Create a CSV string for tableData2
    const csvString2 = csvRows2.join('\n');

    // Create a Blob object for tableData2
    const blob2 = new Blob([csvString2], { type: 'text/csv;charset=utf-8;' });

    // Create a temporary URL for the Blob for tableData2
    const url2 = window.URL.createObjectURL(blob2);

    // Create a temporary <a> element to trigger the download for tableData2
    const a2 = document.createElement('a');
    a2.href = url2;
    a2.download = 'table_data2.csv';
    document.body.appendChild(a2);
    a2.click();
    window.URL.revokeObjectURL(url2);
    document.body.removeChild(a2);
};


  const handleDownload = () => {
    const jsonData = JSON.stringify(imageUrlIp.sec_table);
    const formJsonData = JSON.stringify(imageUrlIp.all_table_data);
    const combinedJson = jsonData + formJsonData;
    const blob = new Blob([combinedJson], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'table_data.json';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };




  return (
    <GridWrapper >
      {/* <Box sx={{ width: '100%', height: '900px',p: 2,  background: `linear-gradient(to bottom, #E6E6FA, #ffffff)`}}> */}
      <Grid container spacing={2} sx={{ paddingTop: 5 }}>
        <Grid item xs={5.8} sx={{ background: `linear-gradient(to bottom, #eae8ed, #ffffff)` }}>
          <div className="App">
          <Box sx={{ width: '100%', height: '1000px' }}>
  {pdf_list.includes(window.globalVar1) ? (
    // If globalVar1 is in pdf_list, display PDF
    <iframe
      src={imageUrlIp.imageUrlIp} // URL of the PDF
      width="100%"
      height="1000px"
      title="PDF Viewer"
      style={{ border: 'none' }}
    />
  ) : word_list.includes(window.globalVar1) ? (
    <div sx={{ padding: 4, paddingTop: 20, width: '80%'}}>
      <div>
        <h4>Text Data</h4>
      </div>
      <div sx={{ padding: 10 , background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '8px'}}>
        <label sx={{ fontSize: '16px', lineHeight: '24px', textAlign: 'justify'  }}>{longText}</label>
      </div>
    </div>
  ): (
    // If neither, display image with annotation tool
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '900px',
        overflow: 'scroll',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '900px',
        }}
      >
        <ReactPictureAnnotation
          id='baseimage'
          image={imageUrlIp.imageUrlIp}
          onSelect={handleSelect}
          onChange={handleSave}
          onDelete={handleDelete}
          onExit={handleExit}
          width={pageSize.width}
          height={pageSize.height}
          annotationData={annotations}
          shape="rect"
          annotationStyle={defaultShapeStyle}

          inputElement={(value, onChange, onDelete) =>
                <form>
                  
                <div  onClick={get_ocr_text}>

                  <Button sx={{
                    color: '#ffffff',
                    border: '1px solid #674f87',
                    borderRadius: '10px',
                    background: 'linear-gradient(to right, #674f87 1%, #ef798a 200%)',
                    '&:hover': {
                      background: 'linear-gradient(to top right, #ef798a 1%, #674f87)',
                      border: '1px solid #674f87',
                      color: '#ffffff'
                    }
                  }} variant="contained" endIcon={<SendIcon />}>
                    
                  </Button>
                </div>
                
              </form>
          }
        />
      </div>
    </div>
  )}
</Box>
          </div>
        </Grid>
        <Grid item xs={5.8} sx={{ background: `linear-gradient(to bottom, #eae8ed, #ffffff)` }}>
          <Box sx={{ width: '100%', height: '800px', typography: 'body1'  }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider',display: 'flex', justifyContent: 'center' }}>
                <TabList onChange={(event, newValue) => setValue(newValue)} aria-label="lab API tabs example"   textColor="white" indicatorColor="white">
                  {/* sx={{ '&.Mui-selected': { background: 'linear-gradient(to right, #9E3C5D, #432F60)', color: 'white' } }} */}
                  <Tab label="Form Data" value="1" sx={{ border: 2, width: 200, borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' ,'&.Mui-selected': { background: 'linear-gradient(to right, #432F60, #9E3C5D )', color: '#ffffff'}  }} />

                  <Tab label="Table Data" value="2" sx={{ border: 2, width: 200, borderTopRightRadius: '10px', borderBottomRightRadius: '10px','&.Mui-selected': { background: 'linear-gradient(to right, #9E3C5D, #432F60)', color: '#ffffff'} }}/>
                </TabList>
              </Box>
              <TabPanel value="1">
                <div style={{ height: '800px', width: '100%' }}>
                  <TableContainer component={Paper} sx={{ height: '100%' }}>
                    <Table aria-label="simple table">
                      <TableHead >
                        <TableRow>
                          {imageUrlIp.all_table_header.map((column) => (
                            <TableCell key={column} align="left" sx={{background:'#191F2B', color:"#ffffff"}}>{column}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {imageUrlIp.all_table_data && imageUrlIp.all_table_data.map((row, rowIndex) => (

                      <TableRow key={row[0].Coordinates}> 
                        {row.map((column, columnIndex) => (
                          <TableCell 
                            key={`T1${rowIndex}-${columnIndex}`}  
                            align="left" 
                            sx={{ 
                              background: 'linear-gradient(to right, #eae8ed, #ffffff)', 
                              whiteSpace: 'nowrap',
                              minWidth: '20px', // Add a minimum width to ensure clickable area
                              minHeight: '20px' // Add a minimum height to ensure clickable area
                            }}
                          >
                            {editingCellT1 !== null && 
                            editingCellT1.rowIndex === rowIndex && 
                            editingCellT1.columnIndex === columnIndex ? (
                              <TextField
                                variant="standard"
                                value={editValueT1}
                                onChange={handleInputChangeT1}
                                onBlur={handleInputBlurT1}
                                fullWidth
                                autoFocus
                              />
                            ) : (
                              <div 
                                onClick={() => handleEditT1(rowIndex, columnIndex, column)}
                                style={{ 
                                  minHeight: '20px', // Ensure the div has a minimum height
                                  cursor: 'text' // Change cursor to indicate editable text
                                }}
                              >
                                {column.Text || '\u00A0'} {/* Use non-breaking space if Text is empty */}
                              </div>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>

                      ))}
                    </TableBody>

                    </Table>
                  </TableContainer>
                </div>
              </TabPanel>
              <TabPanel value="2">
                
              <div style={{ height: '800px', width: '100%' }}>
                  <TableContainer component={Paper} sx={{ height: '100%' }}>
                    <Table  aria-label="simple table">
                      <TableHead >
                        <TableRow>
                          {imageUrlIp.sec_head.map((column) => (
                            <TableCell key={column} align="left" sx={{background:'#191F2B', color:"#ffffff"}}>{column}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {imageUrlIp.sec_table && imageUrlIp.sec_table.map((row, rowIndex) => (
                            <TableRow key={row[0].Coordinates}> 
                              {row.map((column, columnIndex) => (
                                <TableCell key={`T2${rowIndex}-${columnIndex}`} align="left" sx={{ background: 'linear-gradient(to right, #eae8ed, #ffffff)', whiteSpace: 'nowrap' }}>
                                  {editingCell !== null && editingCell.rowIndex === rowIndex && editingCell.columnIndex === columnIndex ? (
                                    <TextField
                                      variant="standard"
                                      value={editValue}
                                      onChange={handleInputChange}
                                      onBlur={handleInputBlur}
                                      fullWidth
                                      autoFocus
                                    />
                                  ) : (
                                    <div onClick={() => handleEdit(rowIndex, columnIndex,column)}>
                                      {column.Text}
                                    </div>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </TabPanel>
            </TabContext>
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{paddingTop:2}}>
              <Grid item xs={3} justifyContent="center" alignItems="center">
                <div >
                <CommonButton variant="outlined"
                  
                  sx={{
                    color: '#ffffff',
                    padding: '5px 40px ',
                    size: '10px',
                    border: '1px solid #674f87',
                    borderRadius: '10px',
                    background: 'linear-gradient(to right, #674f87 1%, #ef798a 200%)',
                    align: 'center',
                    position: 'relative', bottom: 20,
                    width: '80%',
                    '&:hover': {
                      background: 'linear-gradient(to top right, #ef798a 1%, #674f87)',
                      border: '1px solid #674f87',
                      color: '#ffffff'
                    }
                  }}

                >Save
                </CommonButton>
                </div>

              </Grid>
              <Grid item xs={3} justifyContent="center" alignItems="center">
              <div>
                  <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                      color: '#ffffff',
                      padding: '5px 40px ',
                      size: '10px',
                      border: '1px solid #674f87',
                      borderRadius: '10px',
                      width: '80%',
                      background: 'linear-gradient(to right, #674f87 1%, #ef798a 200%)',
                      align: 'center',
                      position: 'relative', bottom: 20,
                      '&:hover': {
                        background: 'linear-gradient(to top right, #ef798a 1%, #674f87)',
                        border: '1px solid #674f87',
                        color: '#ffffff'
                      }
                    }}
  
                  >
                    Download
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem onClick={handleDownloadCSV}>CSV</MenuItem>
                    <MenuItem onClick={handleDownload}>JSON</MenuItem>
                  </Menu>
              </div>
              </Grid>
          </Grid>
          </Box>
          


        </Grid>

      </Grid></GridWrapper>

  );
}

export default Help;
