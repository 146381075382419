// src/components/Settings.js

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import GridWrapper from './common/GridWrapper/GridWrapper';
import TabContext from '@mui/lab/TabContext';
import { BACKEND_URL, getStoredEndpoints, setStoredEndpoints } from '../constants/constants';
import { getAllEndpoints, setEndpoint, getEndpoint } from '../constants/endpoints';
import { 
    Typography, 
    Radio, 
    RadioGroup, 
    FormControlLabel, 
    FormControl, 
    FormLabel, 
    Paper, 
  } from '@mui/material';

function Settings() {
  const [endpoints, setEndpoints] = useState(getAllEndpoints());
  const [loading, setLoading] = useState(true);
  const [selectedEndpoint, setSelectedEndpoint] = useState(getEndpoint('CONVERSATION'));
  const [error, setError] = useState(null);
  const [valueTab, setValueTab] = React.useState('1');
  useEffect(() => {
    fetchEndpoints();
  }, []);

  const fetchEndpoints = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_URL}${getEndpoint('GET_ENDPOINTS')}`);
      if (!response.ok) {
        throw new Error('Failed to fetch endpoints');
      }
      const data = await response.json();
      setEndpoints(data.endpoints);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleEndpointChange = (event) => {
    const value = event.target.value;
    setSelectedEndpoint(value);
    setEndpoint('CONVERSATION', value);
    const updatedEndpoints = {...endpoints, CONVERSATION: value};
    setEndpoints(updatedEndpoints);
    setStoredEndpoints(updatedEndpoints);
  };

  if (loading) return <div>Loading endpoints...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <GridWrapper>
    <Box sx={{ width: '100%', typography: 'body1' }}>
    <TabContext value={valueTab}>
    <Paper elevation={3} sx={{ p: 3, maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Settings
      </Typography>
      <FormControl component="fieldset">
        <FormLabel component="legend" sx={{ mb: 2 }}>
          <Typography variant="h6">Select Default Conversation Endpoint</Typography>
        </FormLabel>
        <RadioGroup
          aria-label="default conversation endpoint"
          name="default-endpoint"
          value={selectedEndpoint}
          onChange={handleEndpointChange}
        >
          {Object.entries(endpoints).map(([key, value]) => (
            <FormControlLabel 
              key={key} 
              value={value} 
              control={<Radio />} 
              label={
                <Typography>
                  <strong>{key}:</strong> {value}
                </Typography>
              } 
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Paper>
    </TabContext>
    </Box>
    </GridWrapper>

  );
}

export default Settings;