
// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Tab from '@mui/material/Tab';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
// import GridWrapper from '../../components/common/GridWrapper/GridWrapper';

// export default function AccountInfo() {
//   const [value, setValue] = React.useState('1');

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <GridWrapper>
//         <Box sx={{ width: '100%', typography: 'body1' }}>
//         <TabContext value={value}>
//             <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//             <TabList onChange={handleChange} aria-label="lab API tabs example">
//                 <Tab label="Form Data" value="1" />
//                 <Tab label="Table Data" value="2" />
//             </TabList>
//             </Box>
//             <TabPanel value="1">Form Data</TabPanel>
//             <TabPanel value="2">Table Data</TabPanel>
//         </TabContext>
//         </Box>
//     </GridWrapper>
//   );
// }

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BarChart from "../../components/charts/BarChart";
import ProcessingTimeChart from '../../components/charts/ProcessingTimeChart';
import AccuracyChart from '../../components/charts/accuracyChart';
import { useNavbar } from '../../components/Navbar/NavbarContext';
import ExploreButton from '../../components/exploreMoreButtons';
import ProcessCount from '../../components/charts/CountChart/ProcessingCount';
const Home = () => {
  const navigate = useNavigate();
    // window.localStorage.setItem('isLoggedIn', false); 
    // navigate('/');
    window.localStorage.removeItem('isLoggedIn');
    const { setCollapsed } = useNavbar();
    setCollapsed(false);
    return (
      <div >
        <h2 style={{color: '#674f87', fontFamily: 'sans-serif', }}>
          Welcome to AiChemist
        </h2>
        <div style={{ display: 'flex', gap: '40px',padding:'10px' }}>
          <AccuracyChart value={90}></AccuracyChart>
          <ProcessingTimeChart value={50000} totalValue={70000}></ProcessingTimeChart>
          <BarChart ></BarChart>
          <ProcessCount value={9565189}></ProcessCount>
    

        </div>
        <div style={{marginTop: '300px', marginLeft: '10px'}}>
          <ExploreButton />
        </div>
      </div>
        
    )
}

export default Home

