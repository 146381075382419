import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { topmenuStyles } from './styles';
import Ai_logo from '../images/AI_white.svg'
import { Height } from '@mui/icons-material';
import logo from '../images/qd_white.png';
import ai_2 from '../images/ai_ch.png'
import Home from '../../pages/Home/Home';
// export default function TopMenu() {
//   return (
//     <AppBar position="fixed" sx={ topmenuStyles.top_menu }>
//         <Toolbar>
//             <Typography variant="h6" noWrap component="div">
//             AiChemist
//             </Typography>
//             {/* Add the "Quadance" heading on the right corner */}
//             <Typography variant="h6" noWrap component="div" sx={{ marginLeft: 'auto' }}>
//             Quadance
//             </Typography>
//         </Toolbar>
//         </AppBar>

//   );
// }
export default function TopMenu() {
  const navigate = useNavigate();
  return (
    <AppBar position="fixed" sx={ topmenuStyles.top_menu }>
        <Toolbar>
          <div onClick={() => navigate('home')}>
            <Typography  noWrap component="img" src={Ai_logo} sx={{marginRight:'auto', width: '10%'}}>
        
            </Typography>
            </div>
            {/* Add the "Quadance" heading on the right corner */}
            <Typography  noWrap component="img" src={logo} sx={{marginLeft:'auto', width: '8%' }}>
            {/* <img src={logo} sx={{Height:'20px'}}/> */}
            </Typography>
            
         
        </Toolbar>
        </AppBar>

  );
}

// import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import { topmenuStyles } from './styles';

// export default function ButtonAppBar() {
//   return (
//     <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
//       <Toolbar sx={{
//         background: 'linear-gradient(90deg, #9E3C5D, #432F60)',
//         animation: 'gradientAnimation 10s linear infinite'
//       }}>
//         <Typography variant="h6" noWrap component="div">
//           AiChemist
//         </Typography>
//         {/* Add the "Quadance" heading on the right corner */}
//         <Typography variant="h6" noWrap component="div" sx={{ marginLeft: 'auto' }}>
//           Quadance
//         </Typography>
//       </Toolbar>
//     </AppBar>
//   );
// }

// <style jsx>{`
//   @keyframes gradientAnimation {
//     0% { background-position: 0% 50%; }
//     100% { background-position: 100% 50%; }
//   }
// `}</style>
