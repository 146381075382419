import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import GridItems from '../../components/GridItems/GridItems';
import ReactImageMagnify from 'react-image-magnify';

import { useNavbar } from '../../components/Navbar/NavbarContext';

const ExtractData = () => {
    const { setCollapsed } = useNavbar();
    setCollapsed(false);
    return (
        <GridItems></GridItems>
    )
}

export default ExtractData

