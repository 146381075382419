import React from 'react';
import styled from 'styled-components';

const ChartContainer = styled.div`
  padding: 0px 20px;
  background-color: #f5f5f5;
  width: 300px;
  height: 300px;
  border-radius: 20px;
`;

const CircularProgress = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 200px;
  margin: auto;
`;

const Svg = styled.svg`
  transform: rotate(-90deg);
`;

const CircleBackground = styled.circle`
  stroke: #fff;
`;

const CirclePath = styled.circle`
  stroke-linecap: round;
  transition: stroke-dashoffset 1s ease;
`;

const TextBackground = styled.div`
  background: linear-gradient(to bottom, #ef798a, #674f87);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 35px;
  border-radius: 50%;
`;

const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  font-weight: lighter;
  color: #fff; 
`;

const ChartHeader = styled.div`
  font-weight: bolder;
  text-align: center;
  line-height: 1
`;

const ChartFooter = styled.div`
  text-align: center;
`;

const AccuracyChart = ({ value, text }) => {
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const strokeWidth = 10;
  const dashOffset = circumference -((value / 100) * circumference) + 30;

  return (
    <ChartContainer>
      <ChartHeader>
        <p style={{fontFamily:'Roboto,sans-serif'}}>Our accuracy</p>
      </ChartHeader>
      <CircularProgress>
        <Svg viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: '#e4bc81', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: '#ff6b6b', stopOpacity: 1 }} />
            </linearGradient>
          </defs>
          <CircleBackground
            cx={radius}
            cy={radius}
            r={radius - strokeWidth / 2}
            strokeWidth={strokeWidth}
            fill="none"
          />
          <CirclePath
            cx={radius}
            cy={radius}
            r={radius - strokeWidth / 2}
            strokeWidth={strokeWidth}
            fill="none"
            stroke={`url(#gradient)`} 
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={dashOffset}
          />
        </Svg>
        <TextBackground>
          <Text>{text || `${value}%`}</Text>
        </TextBackground>
      </CircularProgress>
      <ChartFooter>
        <p style={{fontFamily:'Roboto,sans-serif'}}>Document Extraction</p>
      </ChartFooter>
    </ChartContainer>
  );
};

export default AccuracyChart;
