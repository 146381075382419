import { BACKEND_URL, getStoredEndpoints, setStoredEndpoints } from '../constants/constants';

  let storedEndpoint = getStoredEndpoints()
  console.log(storedEndpoint)
  let endpoints =  {
    GET_ENDPOINTS: '/get_endpoints',
    CONVERSATION: '/it_conversation/llm_text_query',
    }   

  if (storedEndpoint["CONVERSATION"]) {
    endpoints["CONVERSATION"] = storedEndpoint["CONVERSATION"]
  }
  export const getEndpoint = (key) => endpoints[key];
  
  export const setEndpoint = (key, value) => {
    endpoints[key] = value;
  };
  
  
  export const getAllEndpoints = () => ({...endpoints});
  
  export const initializeEndpoints = (savedEndpoints) => {
    endpoints = {...endpoints, ...savedEndpoints};
  };