import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import ChatWithFiles from './chatWithFiles';


export default function ConversationalAI() {
	const [value, setValue] = React.useState('1');
  
	const handleChange = (event, newValue) => {
	  setValue(newValue);
	};


	return (
		// <GridWrapper>
			<Box sx={{ width: '100%', typography: 'body1' }}>
			<TabContext value={value}>
				{<ChatWithFiles/>}
			</TabContext>
			</Box>
		// </GridWrapper>
	);
}
