import React, {useState} from 'react';
import DocCard from '../common/DocCard/DocCard';
import SearchBar1 from '../../components/common/SearchBar/SearchBar1';
import { cardHeaderStyles } from './styles';
import GridWrapper from '../common/GridWrapper/GridWrapper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import myImage from '../images/qd-icon.png'; 
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import EntryIcon from '../images/Entry.svg';
import HandIcon from '../images/Handwritten.svg';
import TableIcon from '../images/Table.svg';
import TamilIcon from '../images/Tamil.svg';
import CensusIcon from '../images/Census.svg';
import DirectoryIcon from '../images/Directory.svg';
import ClassifyIcon from '../images/Classification.svg';
import LeaseIcon from '../images/Lease.svg';
import { sizeHeight, sizeWidth } from '@mui/system';
import { Height } from '@mui/icons-material';


const Docgrid = () => {
    const [searchQuery, setSearchQuery] = useState('');
    
    const handleChange = (query) => {
      setSearchQuery(query);
    };

    const projects = [
      { id: 1, header: 'MyProject-1', content: 'This is the Project-1', icon: <img src={EntryIcon} /> },
      { id: 2, header: 'Project-2', content: 'This is the Project-2', icon: <img src={HandIcon} /> },
      { id: 3, header: 'Project-3', content: 'This is the Project-3', icon: <img src={TamilIcon} /> },
      { id: 4, header: 'Project-4', content: 'This is the Project-4', icon: <img src={CensusIcon} /> },
      { id: 5, header: 'Project-5', content: 'This is the Project-5', icon: <img src={DirectoryIcon} /> },
      { id: 6, header: 'Project-6', content: 'This is the Project-6', icon: <img src={TableIcon} /> },
      { id: 7, header: 'Project-7', content: 'This is the Project-7', icon: <img src={TableIcon} /> },
      { id: 8, header: 'Project-8', content: 'This is the Project-8', icon: <img src={TableIcon} /> },
      { id: 9, header: 'Project-9', content: 'This is the Project-9', icon: <img src={TableIcon} /> },
      { id: 10, header: 'Project-10', content: 'This is the Project-10', icon: <img src={TableIcon} /> },
      { id: 11, header: 'Project-11', content: 'This is the Project-11', icon: <img src={TableIcon} /> },
      { id: 12, header: 'Project-12', content: 'This is the Project-12', icon: <img src={TableIcon} /> },
      { id: 13, header: 'Project-13', content: 'This is the Project-7', icon: <img src={TableIcon} /> },
      { id: 14, header: 'Project-14', content: 'This is the Project-8', icon: <img src={TableIcon} /> },
      { id: 15, header: 'Project-15', content: 'This is the Project-9', icon: <img src={TableIcon} /> },
      { id: 16, header: 'Project-16', content: 'This is the Project-10', icon: <img src={TableIcon} /> },
      { id: 17, header: 'Project-17', content: 'This is the Project-11', icon: <img src={TableIcon} /> },
      { id: 18, header: 'Project-18', content: 'This is the Project-12 zqwvx', icon: <img src={TableIcon} /> }
      ];
    
    const filteredProjects = projects.filter(project =>
      project.header.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.content.toLowerCase().includes(searchQuery.toLowerCase())
    );
      
      
      
      
    return (
        // //, marginLeft:1
        <div>
        <Typography component="div" variant="h9" sx={{marginBottom:'15px', fontSize:'1.5rem'}}> 
        Documentations
        </Typography>
        <SearchBar1 sx={cardHeaderStyles.search}
            placeholder=" Search across all docs"
            onChange={(event) => handleChange(event.target.value)}
            searchBarWidth='920px'
        />
       
          <Grid container spacing={4}   style={{ marginBottom: '16px' }} sx={{width:'92%'}}> 
            {filteredProjects.map((project) => (
              <Grid item xs={4} key={project.id}>
                
                <DocCard
                  header={project.header}
                  content={project.content}
                //   icon={project.icon}
                  click_element={'upload'}
                  project_id={project.id}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      );
}

export default Docgrid;
